import { useMutation, useQueryClient } from "@tanstack/react-query";
import { doc, updateDoc } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { ContactMethod } from "../../models/ContactMethod";

export const useUpdateContactMethods = (providerId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newOrder: ContactMethod[]) => {
      if (!providerId) throw new Error("Provider ID is required");

      const bioRef = doc(fireStoreDb, "zipBios", providerId);
      await updateDoc(bioRef, {
        contactMethods: newOrder,
      });

      return newOrder;
    },
    // Optimistic update
    onMutate: async (newOrder) => {
      // Cancel outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["zipBio", providerId] });

      // Snapshot the previous value
      const previousZipBio = queryClient.getQueryData(["zipBio", providerId]);

      // Optimistically update to the new value
      queryClient.setQueryData(["zipBio", providerId], (old: any) => ({
        ...old,
        contactMethods: newOrder,
      }));

      // Return context with the previous value
      return { previousZipBio };
    },
    onError: (_, __, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(["zipBio", providerId], context?.previousZipBio);
    },
    onSettled: () => {
      // Always refetch after error or success to ensure cache sync
      queryClient.invalidateQueries({ queryKey: ["zipBio", providerId] });
    },
  });
};
