import { useQuery } from "@tanstack/react-query";
import { getDoc, doc } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { ZipBio } from "../../models/ZipBio";

const fetchZipBioById = async (providerId: string): Promise<ZipBio | null> => {
  const zipBioDoc = await getDoc(doc(fireStoreDb, "zipBios", providerId));

  if (!zipBioDoc.exists()) {
    return null;
  }

  return {
    ...zipBioDoc.data(),
    id: zipBioDoc.id,
  } as ZipBio;
};

export const useZipBioQuery = (providerId: string) => {
  return useQuery<ZipBio | null, Error>({
    queryKey: ["zipBio", providerId],
    queryFn: () => fetchZipBioById(providerId),
    enabled: !!providerId,
  });
};
