import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import { LocateFixed } from "lucide-react";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";

type GeolocationState =
  | "idle"
  | "requesting"
  | "success"
  | "denied"
  | "error"
  | "unsupported";

interface GeolocationHandlerProps {
  onLocationSuccess: (position: any) => void;
  onLocationError: (error: GeolocationPositionError) => void;
}

export const GeolocationHandler: React.FC<GeolocationHandlerProps> = ({
  onLocationSuccess,
  onLocationError,
}) => {
  const [geolocationState, setGeolocationState] =
    useState<GeolocationState>("idle");
  const [showModal, setShowModal] = useState<boolean>(false);

  const requestGeolocation = async () => {
    setGeolocationState("requesting");

    try {
      if (Capacitor.isNativePlatform()) {
        // Request permissions for Capacitor (Android/iOS)
        const permissionStatus = await Geolocation.requestPermissions();

        if (permissionStatus.location !== "granted") {
          setGeolocationState("denied");
          setShowModal(true);
          return;
        }

        // Get location using Capacitor Geolocation
        const position = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        });

        // Convert Capacitor position to GeolocationPosition
        const navigatorPosition = {
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            altitude: position.coords.altitude,
            accuracy: position.coords.accuracy,
            altitudeAccuracy: position.coords.altitudeAccuracy as number,
            heading: position.coords.heading,
            speed: position.coords.speed,
            toJSON() {
              return {
                latitude: this.latitude,
                longitude: this.longitude,
                altitude: this.altitude,
                accuracy: this.accuracy,
                altitudeAccuracy: this.altitudeAccuracy,
                heading: this.heading,
                speed: this.speed,
              };
            },
          },
          timestamp: position.timestamp,
        };

        setGeolocationState("success");
        onLocationSuccess(navigatorPosition);
      } else {
        // Fallback to browser geolocation for web
        if (!navigator.geolocation) {
          setGeolocationState("unsupported");
          return;
        }

        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            setGeolocationState("success");
            onLocationSuccess(position);
          },
          (error: GeolocationPositionError) => {
            if (error.code === error.PERMISSION_DENIED) {
              setGeolocationState("denied");
              setShowModal(true);
            } else {
              setGeolocationState("error");
              onLocationError(error);
            }
          }
        );
      }
    } catch (error) {
      console.error("Geolocation error:", error);
      setGeolocationState("error");
      onLocationError(error as GeolocationPositionError);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button
        size="sm"
        variant="light"
        color="primary"
        startContent={<LocateFixed className="w-4" />}
        className="font-medium px-0"
        onPress={requestGeolocation}
        isDisabled={
          geolocationState === "requesting" ||
          geolocationState === "unsupported"
        }
      >
        {geolocationState === "requesting"
          ? "Localizando..."
          : "Usar localização atual"}
      </Button>

      <Modal isOpen={showModal} onClose={handleCloseModal}>
        <ModalContent>
          <ModalHeader>Permissão de Localização Negada</ModalHeader>
          <ModalBody>
            Para usar sua localização atual, você precisa permitir o acesso à
            sua localização nas configurações do{" "}
            {Capacitor.isNativePlatform() ? "dispositivo" : "navegador"}.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onPress={handleCloseModal}>
              Entendi
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
