import { useState, useEffect } from "react";
import { Avatar } from "@nextui-org/react";
import { motion, AnimatePresence } from "framer-motion";
import { UploadProgressOverlay } from "../UploadProgressOverlay";
import { UploadState } from "../../../hooks/useFileUpload";

interface FirebaseAvatarProps {
  imageUrl: string;
  name: string;
  size?: "sm" | "md" | "lg";
  radius?: "sm" | "md" | "lg" | "full";
  className?: string;
  isBordered?: boolean;
  uploadState?: UploadState;
}

export const FirebaseAvatar = ({
  imageUrl,
  name,
  size = "lg",
  radius = "full",
  className = "",
  isBordered = false,
  uploadState,
}: FirebaseAvatarProps) => {
  const [loadedImageUrl, setLoadedImageUrl] = useState("");

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.onload = () => {
        setLoadedImageUrl(imageUrl);
      };
      img.src = imageUrl;
    } else {
      setLoadedImageUrl("");
    }
  }, [imageUrl]);

  return (
    <div className="relative">
      <AnimatePresence mode="wait">
        <motion.div
          key={loadedImageUrl}
          initial={{
            opacity: 0,
            filter: "blur(4px)",
          }}
          animate={{
            opacity: 1,
            filter: "blur(0px)",
          }}
          exit={{
            opacity: 0,
            filter: "blur(4px)",
          }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
          }}
          style={{
            willChange: "filter",
          }}
        >
          <Avatar
            name={name}
            src={loadedImageUrl}
            size={size}
            radius={radius}
            className={`w-16 h-16 ${className}`}
            classNames={{
              img: loadedImageUrl ? "opacity-100" : "opacity-0",
              fallback: !loadedImageUrl ? "opacity-100" : "opacity-0",
            }}
            isBordered={isBordered}
          />
        </motion.div>
      </AnimatePresence>

      <AnimatePresence>
        {(uploadState?.isUploading || uploadState?.isSuccess) && (
          <UploadProgressOverlay
            uploadState={uploadState}
            size={size}
            className="rounded-inherit"
          />
        )}
      </AnimatePresence>
    </div>
  );
};
