import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@nextui-org/react";
import { Ellipsis, FileUp, Link, Pencil, Plus, Undo2 } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/auth.context";
import { fireStoreDb, storage } from "../../../services/firebase/firebase";
import { ContactMethod } from "../../../models/ContactMethod";
import { EditProfileDrawer } from "../../Organisms/EditProfileDrawer";
import { ContactMethodCard } from "../../Molecules/ContactMethodCard";
import { AddContactMethodCard } from "../../Molecules/AddContactMethodCard";
import { AnimatePresence, Reorder } from "framer-motion";
import { CustomLinkDrawer } from "../../Organisms/CustomLinkDrawer";
import { useZipBioQuery } from "../../../hooks/queries/useZipBioQuery";
import { FirebaseAvatar } from "../../Atoms/FirebaseAvatar";
import { useFileUpload } from "../../../hooks/useFileUpload";
import { useUpdateContactMethods } from "../../../hooks/queries/useUpdateContactMethods";

export const ZipBio: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const providerId = currentUser?.providerId || "";

  const {
    isOpen: isProfileOpen,
    onOpen: onProfileOpen,
    onOpenChange: onProfileOpenChange,
  } = useDisclosure();

  const {
    isOpen: isLinkOpen,
    onOpen: onLinkOpen,
    onOpenChange: onLinkOpenChange,
  } = useDisclosure();

  const { data: zipBio, isLoading, refetch } = useZipBioQuery(providerId);
  const { mutate: updateOrder } = useUpdateContactMethods(providerId);

  const [isCreatingProfile, setIsCreatingProfile] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [bio, setBio] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [contactMethods, setContactMethods] = useState<ContactMethod[]>(
    zipBio?.contactMethods || []
  );
  const [isAddingContact, setIsAddingContact] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { uploadState, handleFileUpload } = useFileUpload({
    storage,
    fireStoreDb,
    providerId,
    collectionName: "zipBios",
    onSuccess: async () => {
      await refetch();
    },
  });

  useEffect(() => {
    if (uploadState.previewUrl) {
      setImage(uploadState.previewUrl);
    }
  }, [uploadState.previewUrl]);

  useEffect(() => {
    if (zipBio) {
      setName(zipBio.name);
      setBio(zipBio.bio);
      setImage(zipBio.imageUrl || "");
      setContactMethods(zipBio.contactMethods);
      setIsCreatingProfile(false);
    }
  }, [zipBio]);

  useEffect(() => {
    if (!isLinkOpen && isCreatingProfile && !zipBio) {
      setIsCreatingProfile(false);
    }
  }, [isLinkOpen, isCreatingProfile, zipBio]);

  const handleDrawerOpen = () => {
    onProfileOpen();
    setIsPopoverOpen(false);
  };

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    await handleFileUpload(file);
  };

  const handleReorder = async (newOrder: ContactMethod[]) => {
    setContactMethods(newOrder);
    updateOrder(newOrder);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!zipBio && !isCreatingProfile) {
    return (
      <main className="flex flex-col justify-center items-center gap-8 p-4 min-h-svh pb-20 w-full">
        <div className="flex flex-col items-center justify-center gap-4 max-w-md mx-auto text-center">
          <Link size={32} />
          <h1 className="text-2xl font-semibold">Crie sua ZipBio</h1>
          <p className="text-default-600">
            Configure seu link personalizado para compartilhar seus métodos de
            contato.
          </p>
          <Button
            color="primary"
            size="lg"
            className="w-full"
            onPress={() => {
              setIsCreatingProfile(true);
              onLinkOpen();
            }}
          >
            Começar
          </Button>
        </div>
      </main>
    );
  }

  if (isCreatingProfile) {
    return (
      <main className="flex flex-col justify-center items-center gap-8 p-4 min-h-svh pb-20 w-full">
        <div className="flex flex-col items-center justify-center gap-4 max-w-md mx-auto text-center">
          <Link size={32} />
          <h1 className="text-2xl font-semibold">Crie sua ZipBio</h1>
          <p className="text-default-600">
            Configure seu link personalizado para compartilhar seus métodos de
            contato.
          </p>
        </div>

        <CustomLinkDrawer
          isOpen={isLinkOpen}
          onOpenChange={onLinkOpenChange}
          initialData={{ id: providerId }}
          onSuccess={refetch}
          isDismissable={true}
        />
      </main>
    );
  }

  return (
    <main className="flex flex-col justify-start items-center gap-8 p-4 min-h-svh pb-20 w-full">
      <div className="flex w-full justify-start items-stretch gap-4">
        <Button
          color="warning"
          variant="flat"
          size="lg"
          radius="lg"
          className="min-w-max aspect-square p-2 bg-warning-50 text-warning"
          onPress={() => navigate("/profile")}
        >
          <Undo2 aria-label="Voltar" />
        </Button>
        <div className={`flex items-center justify-center gap-2 rounded-large`}>
          <h1 className={`text-2xl font-semibold text-default-900`}>ZipBio</h1>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center gap-4 w-full md:max-w-md">
        <div className="flex flex-col items-center justify-center gap-3 w-full">
          <div className="w-full bg-default-200 rounded-medium p-2">
            <h3 className="text-default-600 font-semibold text-xs">
              Suas Informações
            </h3>
          </div>

          <div className="flex flex-col gap-3 w-full">
            <div className="w-full flex justify-between items-center pl-0.5 pr-1">
              <div className="flex gap-3 items-center">
                <div className="relative group">
                  <FirebaseAvatar
                    name={name}
                    imageUrl={image}
                    size="lg"
                    radius="md"
                    className="w-16 h-16"
                    uploadState={uploadState}
                  />
                  <Button
                    isIconOnly
                    size="sm"
                    radius="full"
                    variant="flat"
                    color="default"
                    className="absolute -bottom-1.5 -right-1.5 bg-default-900/75 text-white shadow-lg min-w-7 w-7 min-h-7 h-7"
                    onPress={handleImageClick}
                  >
                    <Pencil size={12} />
                  </Button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    hidden
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>

                <div className="flex flex-col">
                  <span className="font-semibold">{name}</span>
                  <span className="text-neutral-500 text-xs">
                    {bio || "Adicionar Bio"}
                  </span>
                </div>
              </div>

              <Popover
                placement="bottom-end"
                isOpen={isPopoverOpen}
                onOpenChange={(open) => setIsPopoverOpen(open)}
                showArrow
              >
                <PopoverTrigger>
                  <Button
                    isIconOnly
                    aria-label="Opções"
                    variant="flat"
                    color="primary"
                    radius="full"
                    onPress={handleImageClick}
                  >
                    <Ellipsis />
                  </Button>
                </PopoverTrigger>

                <PopoverContent className="flex flex-col gap-1 p-2 rounded-large items-start">
                  <Button
                    color="default"
                    variant="light"
                    size="lg"
                    radius="md"
                    startContent={<FileUp />}
                    className="w-full font-medium justify-start"
                    onPress={handleImageClick}
                  >
                    Alterar Foto
                  </Button>
                  <Button
                    color="default"
                    variant="light"
                    size="lg"
                    radius="md"
                    startContent={<Pencil />}
                    className="w-full font-medium justify-start"
                    onPress={handleDrawerOpen}
                  >
                    Alterar Nome e Bio
                  </Button>
                </PopoverContent>
              </Popover>
            </div>

            <Button
              variant="flat"
              color="warning"
              radius="md"
              startContent={<Link />}
              className="font-medium"
              onPress={onLinkOpen}
            >
              Seu Link
            </Button>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center gap-3 w-full">
          <div className="w-full bg-default-200 rounded-medium p-2">
            <h3 className="text-default-600 font-semibold text-xs">
              Seus Métodos de Contato
            </h3>
          </div>

          <div className="w-full flex flex-col gap-4">
            <div className="w-full flex flex-col gap-2 justify-center items-center">
              <AnimatePresence mode="popLayout">
                <Reorder.Group
                  axis="y"
                  values={contactMethods}
                  onReorder={handleReorder}
                  className="w-full flex flex-col gap-2"
                >
                  {contactMethods.map((method) => (
                    <ContactMethodCard
                      key={method.id}
                      contactMethod={method}
                      providerId={providerId}
                      onSuccess={refetch}
                    />
                  ))}
                </Reorder.Group>
                {isAddingContact && (
                  <AddContactMethodCard
                    providerId={providerId}
                    onSuccess={refetch}
                    onCancel={() => setIsAddingContact(false)}
                  />
                )}
              </AnimatePresence>
            </div>

            {!isAddingContact && (
              <Button
                color="primary"
                variant="solid"
                size="lg"
                radius="lg"
                startContent={<Plus />}
                className="font-medium"
                onPress={() => setIsAddingContact(true)}
              >
                Adicionar
              </Button>
            )}
          </div>
        </div>
      </div>

      <EditProfileDrawer
        isOpen={isProfileOpen}
        onOpenChange={onProfileOpenChange}
        initialData={{
          id: providerId || "",
          name: zipBio?.name || "",
          bio: zipBio?.bio || "",
        }}
        providerId={providerId}
        onSuccess={refetch}
      />

      <CustomLinkDrawer
        isOpen={isLinkOpen}
        onOpenChange={onLinkOpenChange}
        initialData={{
          id: providerId || "",
          customLink: zipBio?.customLink,
        }}
        onSuccess={refetch}
      />
    </main>
  );
};
