import { motion, AnimatePresence } from "framer-motion";
import { CheckCircle2 } from "lucide-react";
import { UploadState } from "../../../hooks/useFileUpload";

interface UploadProgressOverlayProps {
  uploadState: UploadState;
  className?: string;
  size?: "sm" | "md" | "lg";
}

const sizeMap = {
  sm: {
    container: "w-8 h-8",
    wrapper: "w-6 h-6",
    text: "text-xs",
    icon: 24,
  },
  md: {
    container: "w-10 h-10",
    wrapper: "w-8 h-8",
    text: "text-sm",
    icon: 32,
  },
  lg: {
    container: "w-12 h-12",
    wrapper: "w-10 h-10",
    text: "text-base",
    icon: 48,
  },
};

export const UploadProgressOverlay = ({
  uploadState,
  className = "",
  size = "lg",
}: UploadProgressOverlayProps) => {
  const progressCircleVariants = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (progress: number) => ({
      pathLength: progress / 100,
      opacity: 1,
      transition: {
        pathLength: {
          type: "spring",
          duration: 1,
          bounce: 0,
        },
        opacity: { duration: 0.2 },
      },
    }),
  };

  const spinningContainerVariants = {
    animate: {
      rotate: 360,
      transition: {
        duration: 3,
        ease: "linear",
        repeat: Infinity,
      },
    },
  };

  const successAnimation = {
    initial: { scale: 0 },
    animate: {
      scale: 1,
      transition: {
        scale: {
          type: "spring",
          stiffness: 100, // Reduced stiffness for smoother motion
          damping: 10, // Reduced damping for more bounce
          mass: 0.8, // Added mass for more natural physics
        },
      },
    },
    exit: {
      scale: 0,
      transition: {
        duration: 0.4,
        ease: "backIn", // Changed to backIn for more dynamic exit
      },
    },
  };

  // Don't show anything if not uploading and not success state
  if (!uploadState.isUploading && !uploadState.isSuccess) return null;

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 25,
      }}
      className={`absolute inset-0 flex items-center justify-center bg-black/50 ${className} rounded-medium`}
    >
      <AnimatePresence mode="wait">
        {uploadState.isSuccess ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 flex items-center justify-center bg-success/10 backdrop-blur-[8px] rounded-medium"
          >
            <motion.div
              variants={successAnimation}
              initial="initial"
              animate="animate"
              exit="exit"
              className="flex items-center justify-center"
            >
              <CheckCircle2
                className="text-success"
                size={sizeMap[size].icon}
              />
            </motion.div>
          </motion.div>
        ) : (
          <div className={`relative ${sizeMap[size].container}`}>
            <motion.div
              variants={spinningContainerVariants}
              animate="animate"
              className="absolute inset-0"
            >
              <svg
                className="absolute inset-0"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2A10 10 0 1 0 22 12 10 10 0 0 0 12 2"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  className="opacity-20"
                />
              </svg>

              <div className="absolute inset-0">
                <svg
                  className="absolute inset-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <motion.path
                    d="M12 2A10 10 0 1 0 22 12 10 10 0 0 0 12 2"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    variants={progressCircleVariants}
                    initial="hidden"
                    animate="visible"
                    custom={uploadState.progress}
                  />
                </svg>
              </div>
            </motion.div>

            <div className="absolute inset-0 flex items-center justify-center">
              <motion.span
                className={`text-white font-medium text-xs ${sizeMap[size].text}`}
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 25,
                  delay: 0.2,
                }}
              >
                {Math.round(uploadState.progress)}%
              </motion.span>
            </div>
          </div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
