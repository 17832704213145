import React from "react";
import { NameInput } from "../../Molecules/NameInput";
import { Button } from "@nextui-org/react";
import { formatPhoneNumber } from "../../../utils/phoneNumberUtils";
import { AuthContextUserProps } from "../../../contexts/auth.context";

interface VerificationSectionProps {
  name: string;
  setName: (name: string) => void;
  updateUser: (data: Partial<AuthContextUserProps>) => void;
  isUpdateUserLoading: boolean;
  currentName: string;
  email: string;
  isEmailVerified: boolean;
  phoneNumber: string;
  isPhoneVerified: boolean;
  handleEmailVerification: () => void;
  isEmailVerificationLoading: boolean;
  onOpenPhoneVerification: () => void;
}

export const VerificationSection: React.FC<VerificationSectionProps> = ({
  name,
  setName,
  updateUser,
  isUpdateUserLoading,
  currentName,
  email,
  isEmailVerified,
  phoneNumber,
  isPhoneVerified,
  handleEmailVerification,
  isEmailVerificationLoading,
  onOpenPhoneVerification,
}) => (
  <div className="flex flex-col gap-2">
    <div className="w-full bg-default-200 rounded-medium p-2">
      <h3 className="text-default-600 font-semibold text-xs">
        Verificação do Perfil
      </h3>
    </div>
    <div className="flex flex-col gap-1">
      <NameInput
        name={name}
        setName={setName}
        updateUser={updateUser}
        isLoading={isUpdateUserLoading}
        currentName={currentName}
      />
      <Button
        size="lg"
        variant="solid"
        className="font-medium"
        color={isEmailVerified ? "success" : "primary"}
        isDisabled={isEmailVerified}
        isLoading={isEmailVerificationLoading}
        onPress={handleEmailVerification}
      >
        {isEmailVerified ? `E-mail: ${email}` : "Enviar e-mail de verificação"}
      </Button>
      <Button
        size="lg"
        variant="solid"
        className="font-medium"
        color={isPhoneVerified ? "success" : "primary"}
        isDisabled={isPhoneVerified}
        onPress={onOpenPhoneVerification}
      >
        {isPhoneVerified
          ? `Telefone: ${formatPhoneNumber(phoneNumber)}`
          : "Enviar SMS de verificação"}
      </Button>
    </div>
  </div>
);
