export const validateEmail = (value: string) => {
  // Basic length check
  if (value.length > 254 || value.length < 5) return false;

  // Regex for comprehensive email validation
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  // Additional checks
  if (!emailRegex.test(value)) return false;

  // Check for consecutive dots
  if (/\.{2}/.test(value)) return false;

  // Ensure valid characters and format
  const [local, domain] = value.split("@");

  // Check local part and domain don't start or end with a dot
  if (
    local.startsWith(".") ||
    local.endsWith(".") ||
    domain.startsWith(".") ||
    domain.endsWith(".")
  ) {
    return false;
  }

  return true;
};
