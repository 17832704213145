import "./services/firebase/firebase";
import { QueryClientProvider } from "@tanstack/react-query";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { toast } from "react-toastify";
import { queryClient } from "./libs/queryClient";
import { AuthProvider } from "./contexts/auth.context";
import { ApplicationRoutes } from "./router";
import { useNavigate } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import "dayjs/locale/pt-br";
// import { useRegisterSW } from "virtual:pwa-register/react";
import { RecaptchaProvider } from "./contexts/recaptcha.context";
import { InstallPrompt } from "./components/Molecules/InstallPrompt";
import { NotificationPrompt } from "./components/Molecules/NotificationPrompt";
import { ErrorBoundary } from "@sentry/react";
import { Error } from "./components/Pages/Error";

export const App = () => {
  const navigate = useNavigate();

  // const { updateServiceWorker } = useRegisterSW({
  //   onNeedRefresh() {
  //     toast.info(
  //       "Uma nova versão do app está disponível. Aguarde enquanto atualizamos.",
  //       {
  //         type: "info",
  //         isLoading: false,
  //         autoClose: 2000,
  //       }
  //     );
  //     setTimeout(() => {
  //       updateServiceWorker(true);
  //     }, 2000);
  //   },
  //   onOfflineReady() {
  //     console.log("The app is ready to work offline");
  //   },
  //   onRegisteredSW(_, r) {
  //     r &&
  //       setInterval(() => {
  //         r.update();
  //       }, 60 * 60 * 1000); // Check for updates every hour
  //   },
  // });

  return (
    <RecaptchaProvider>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <NextUIProvider navigate={navigate} locale="pt-BR">
              <ErrorBoundary
                fallback={({ error, resetError }) => (
                  <Error error={error} resetErrorBoundary={resetError} />
                )}
              >
                <ApplicationRoutes />
              </ErrorBoundary>
              <InstallPrompt />
              <NotificationPrompt />
            </NextUIProvider>
          </LocalizationProvider>
        </QueryClientProvider>
      </AuthProvider>
    </RecaptchaProvider>
  );
};
