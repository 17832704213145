// types.ts
export interface UploadState {
  progress: number;
  isUploading: boolean;
  isSuccess: boolean;
  previewUrl: string;
}

export interface UseFileUploadOptions {
  storage: any; // Replace with proper Firebase storage type
  fireStoreDb: any; // Replace with proper Firestore type
  providerId: string;
  collectionName: string;
  onSuccess?: () => void;
}

export interface UseFileUploadReturn {
  uploadState: UploadState;
  handleFileUpload: (file: File) => Promise<void>;
  resetUploadState: () => void;
}

// useFileUpload.ts
import { useState, useRef } from "react";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const INITIAL_UPLOAD_STATE: UploadState = {
  progress: 0,
  isUploading: false,
  isSuccess: false,
  previewUrl: "",
};

export const useFileUpload = ({
  storage,
  fireStoreDb,
  providerId,
  collectionName,
  onSuccess,
}: UseFileUploadOptions): UseFileUploadReturn => {
  const [uploadState, setUploadState] =
    useState<UploadState>(INITIAL_UPLOAD_STATE);

  const progressTimerRef = useRef<NodeJS.Timeout | null>(null);
  const successTimerRef = useRef<NodeJS.Timeout | null>(null);

  const resetUploadState = () => {
    setUploadState(INITIAL_UPLOAD_STATE);
  };

  const cleanupTimers = () => {
    if (progressTimerRef.current) {
      clearInterval(progressTimerRef.current);
      progressTimerRef.current = null;
    }
    if (successTimerRef.current) {
      clearTimeout(successTimerRef.current);
      successTimerRef.current = null;
    }
  };

  const simulateProgress = (actualProgress: number) => {
    cleanupTimers();

    const remaining = 100 - actualProgress;
    const steps = Math.floor(remaining / 5);
    const interval = 100;

    progressTimerRef.current = setInterval(() => {
      setUploadState((prev) => {
        const increment = Math.min(5, 100 - prev.progress);
        const newProgress = prev.progress + increment;

        if (newProgress >= 100) {
          cleanupTimers();
        }

        return {
          ...prev,
          progress: newProgress,
        };
      });
    }, interval * (steps / 10));
  };

  const handleFileUpload = async (file: File): Promise<void> => {
    if (!providerId) {
      toast.error("Provider ID is required");
      return;
    }

    try {
      cleanupTimers();

      setUploadState({
        progress: 0,
        isUploading: true,
        isSuccess: false,
        previewUrl: URL.createObjectURL(file),
      });

      const filename = `${providerId}-profile-img`;
      const storageRef = ref(storage, `files/${filename}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const actualProgress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setUploadState((prev) => ({
            ...prev,
            progress: Math.min(actualProgress, 90),
          }));

          if (actualProgress > 90) {
            simulateProgress(actualProgress);
          }
        },
        (error) => {
          console.error(error);
          setUploadState((prev) => ({
            ...prev,
            isUploading: false,
            progress: 0,
          }));
          toast.error("Erro ao enviar imagem");
        },
        async () => {
          try {
            const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

            setUploadState((prev) => ({
              ...prev,
              progress: 100,
              isUploading: false,
              isSuccess: true,
            }));

            await updateDoc(doc(fireStoreDb, collectionName, providerId), {
              imageUrl: downloadUrl,
            });

            successTimerRef.current = setTimeout(() => {
              setUploadState((prev) => ({
                ...prev,
                isSuccess: false,
              }));
            }, 1500);

            onSuccess?.();
            toast.success("Imagem enviada com sucesso!");
          } catch (error) {
            console.error("Error updating provider:", error);
            toast.error("Erro ao enviar imagem, por favor, tente novamente.");
          }
        }
      );
    } catch (error) {
      console.error("Upload error:", error);
      setUploadState((prev) => ({
        ...prev,
        isUploading: false,
        progress: 0,
      }));
      toast.error("Erro ao enviar imagem");
    }
  };

  return {
    uploadState,
    handleFileUpload,
    resetUploadState,
  };
};
