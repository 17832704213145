export const BookingInfo = ({
  icon: Icon,
  text,
  iconColor,
  textColor,
  className,
}: {
  icon: any;
  text: string;
  iconColor: string;
  textColor: string;
  className?: string;
}) => (
  <div
    className={`flex items-center justify-start gap-1.5 p-2 rounded-medium  ${className}`}
  >
    <Icon className={`${iconColor} w-6 stroke-[2]`} />
    <span className={`text-sm font-medium ${textColor}`}>{text}</span>
  </div>
);
