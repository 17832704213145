import React from "react";
import { Globe } from "lucide-react";
import { Instagram } from "../../../assets/Instagram";
import { WhatsApp } from "../../../assets/WhatsApp";
import { TikTok } from "../../../assets/TikTok";
import { Facebook } from "../../../assets/Facebook";
import { YouTube } from "../../../assets/YouTube";

interface IconProps {
  size?: "sm" | "lg";
  className?: string;
}

// Create size-aware icon components
export const InstagramIcon: React.FC<IconProps> = ({
  size = "sm",
  className,
}) => (
  <Instagram
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    className={className}
  />
);

export const WhatsAppIcon: React.FC<IconProps> = ({
  size = "sm",
  className,
}) => (
  <WhatsApp
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    className={className}
  />
);

export const TikTokIcon: React.FC<IconProps> = ({ size = "sm", className }) => (
  <TikTok
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    className={className}
  />
);

export const FacebookIcon: React.FC<IconProps> = ({
  size = "sm",
  className,
}) => (
  <Facebook
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    className={className}
  />
);

export const YouTubeIcon: React.FC<IconProps> = ({
  size = "sm",
  className,
}) => (
  <YouTube
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    className={className}
  />
);

export const GlobeIcon: React.FC<IconProps> = ({ size = "sm", className }) => (
  <Globe size={size === "sm" ? 24 : 32} className={className} />
);
