import { doc, getDoc, setDoc } from "firebase/firestore";
import { fireStoreDb, functions } from "../../services/firebase/firebase";
import { Appointment } from "../../models/Appointment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { httpsCallable } from "firebase/functions";

interface CompleteAppointmentParams {
  id: string;
  providerName: string;
}

const completeAppointment = async ({
  id,
  providerName,
}: CompleteAppointmentParams) => {
  try {
    const appointmentDoc = await getDoc(doc(fireStoreDb, "appointments", id));
    const userId = appointmentDoc.data()?.userId;

    await setDoc(
      doc(fireStoreDb, "appointments", id),
      {
        status: "COMPLETED",
      } as Appointment,
      { merge: true }
    );

    if (userId) {
      const sendNotification = httpsCallable(
        functions,
        "sendAppointmentNotification",
        { limitedUseAppCheckTokens: true }
      );

      try {
        await sendNotification({
          appointmentId: id,
          userId,
          title: "Agendamento Concluído",
          body: `Seu agendamento Zip Saúde com ${providerName} foi concluído. Avalie o(a) profissional.`,
          URL: `/appointments/${id}`,
        });
        console.log("Notification sent successfully");
      } catch (error) {
        throw error;
      }
    }
  } catch (error) {
    throw error;
  }
};

export const useCompleteAppointment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: CompleteAppointmentParams) =>
      completeAppointment(params),

    onMutate: async (newAppointment) => {
      await queryClient.cancelQueries({ queryKey: ["appointments"] });
      const previousAppointments = queryClient.getQueryData(["appointments"]);

      queryClient.setQueryData(["appointments"], (old: any) => {
        if (!old || !old.pages) {
          console.warn("Appointments data structure is not as expected");
          return old;
        }

        return {
          ...old,
          pages: old.pages.map((page: Appointment[]) =>
            Array.isArray(page)
              ? page.map((appointment) =>
                  appointment.id === newAppointment.id
                    ? { ...appointment, status: "COMPLETED" }
                    : appointment
                )
              : page
          ),
        };
      });

      return { previousAppointments };
    },

    onError: (error, _, context) => {
      console.error("Error in completeAppointment mutation:", error);
      if (context?.previousAppointments) {
        queryClient.setQueryData(
          ["appointments"],
          context.previousAppointments
        );
      }
    },

    onSettled: async (_, __, params) => {
      await queryClient.invalidateQueries({ queryKey: ["appointments"] });
      await queryClient.invalidateQueries({
        queryKey: ["appointment", params.id],
      });
    },
  });
};
