import React, { useState, useEffect } from "react";
import { Button, Input, Textarea } from "@nextui-org/react";
import { Drawer } from "vaul";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { fireStoreDb } from "../../../services/firebase/firebase";
import { toast } from "react-toastify";
import { ZipBio } from "../../../models/ZipBio";
import { Check } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

interface EditProfileDrawerProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  initialData: {
    id: string;
    name: string;
    bio: string;
  };
  providerId: string;
  onSuccess: () => void;
}

export const EditProfileDrawer: React.FC<EditProfileDrawerProps> = ({
  isOpen,
  onOpenChange,
  initialData,
  providerId,
  onSuccess,
}) => {
  const [name, setName] = useState(initialData.name);
  const [bio, setBio] = useState(initialData.bio);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setName(initialData.name);
      setBio(initialData.bio);
      setSaveSuccess(false);
    }
  }, [isOpen, initialData]);

  const createNewZipBio = async (docRef: any): Promise<void> => {
    const newZipBio: Partial<ZipBio> = {
      providerId,
      name,
      bio,
      imageUrl: "",
      contactMethods: [],
      customLink: "",
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    await setDoc(docRef, newZipBio);
  };

  const updateExistingZipBio = async (docRef: any): Promise<void> => {
    const updates = {
      name,
      bio,
      updatedAt: new Date(),
    };

    await updateDoc(docRef, updates);
  };

  const handleSave = async () => {
    if (!name.trim()) {
      toast.error("O nome é obrigatório");
      return;
    }

    setIsSaving(true);

    try {
      const docRef = doc(fireStoreDb, "zipBios", providerId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateExistingZipBio(docRef);
      } else {
        await createNewZipBio(docRef);
      }

      setSaveSuccess(true);
      toast.success("Perfil atualizado com sucesso!");

      // Delay closing and reset
      setTimeout(() => {
        onSuccess();
        onOpenChange(false);
        setSaveSuccess(false);
      }, 1500);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Erro ao atualizar perfil. Tente novamente.");
      setSaveSuccess(false);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Drawer.Root open={isOpen} onOpenChange={onOpenChange}>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 z-10 bg-black/75 backdrop-blur-sm" />
        <Drawer.Content className="bg-white flex flex-col rounded-t-large z-50 mt-24 fixed bottom-0 left-0 right-0">
          <Drawer.Handle className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-default-300 my-4" />
          <div className="p-4 bg-white rounded-t-large flex-1">
            <Drawer.Title className="text-center text-xl font-semibold text-default-900 mb-4 px-0.5">
              Alterar Nome e Bio
            </Drawer.Title>
            <div className="max-w-md mx-auto flex flex-col gap-12">
              <div className="flex flex-col gap-4">
                <Input
                  size="lg"
                  radius="md"
                  variant="flat"
                  label="Nome"
                  type="text"
                  maxLength={30}
                  value={name}
                  onValueChange={setName}
                  isDisabled={isSaving}
                  isRequired
                  errorMessage={!name.trim() && "O nome é obrigatório"}
                  endContent={
                    <div className="pointer-events-none flex items-center">
                      <span className="text-default-400 text-small">
                        {name.length}/30
                      </span>
                    </div>
                  }
                />
                <Textarea
                  className="w-full"
                  label="Bio"
                  size="lg"
                  maxRows={2}
                  maxLength={80}
                  value={bio}
                  onValueChange={setBio}
                  isDisabled={isSaving}
                  description={
                    <div className="flex justify-end">
                      <span className="text-default-400 text-small">
                        {bio.length}/80
                      </span>
                    </div>
                  }
                />
              </div>
              <motion.div
                layout
                animate={{ scale: saveSuccess ? 0.95 : 1 }}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
              >
                <Button
                  color={saveSuccess ? "success" : "primary"}
                  className="w-full"
                  size="lg"
                  radius="md"
                  onPress={handleSave}
                  isLoading={isSaving}
                  isDisabled={!name.trim() || saveSuccess}
                >
                  <AnimatePresence mode="wait">
                    {saveSuccess ? (
                      <motion.div
                        key="success"
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5 }}
                        className="flex items-center gap-2"
                      >
                        <Check size={20} />
                        <span>Perfil Salvo!</span>
                      </motion.div>
                    ) : (
                      <motion.div
                        key="save"
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5 }}
                      >
                        {isSaving ? "Salvando..." : "Salvar Alterações"}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Button>
              </motion.div>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
