import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Card,
  CardBody,
} from "@nextui-org/react";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  reauthenticateWithPopup,
  deleteUser,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AlertTriangle, Trash2, AlertCircle, ArrowLeft } from "lucide-react";
import { toast } from "react-toastify";
import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { GoogleIcon } from "../../../assets/GoogleIcon";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../../../contexts/auth.context";
import { PasswordInput } from "../../Molecules/Inputs";

const contentVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      type: "spring",
      stiffness: 300,
      damping: 30,
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: { duration: 0.2 },
  },
};

const warningVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      type: "spring",
      stiffness: 300,
      damping: 30,
    },
  },
};

export const DeleteAccount = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [authProvider, setAuthProvider] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const navigate = useNavigate();
  const auth = getAuth();
  const { currentUser } = useAuth();

  const getRedirectPath = () => {
    if (!currentUser) return "/";

    const userRole = currentUser.role;
    switch (userRole) {
      case "provider":
        return "/appointments";
      case "admin":
        return "/clinics";
      default:
        return "/";
    }
  };

  const handleNavigateBack = () => {
    const path = getRedirectPath();
    navigate(path);
  };

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setAuthProvider(
        currentUser.providerData[0]?.providerId === "google.com"
          ? "google"
          : "password"
      );
    }
  }, []);

  const handleGoogleReauthentication = async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        await FirebaseAuthentication.signInWithGoogle();
      } else {
        const provider = new GoogleAuthProvider();
        await reauthenticateWithPopup(auth.currentUser!, provider);
      }
      return true;
    } catch (error) {
      console.error("Google reauthentication error:", error);
      throw error;
      return false;
    }
  };

  const handlePasswordReauthentication = async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        await FirebaseAuthentication.signInWithEmailAndPassword({
          email: auth.currentUser?.email || "",
          password,
        });
      } else {
        await signInWithEmailAndPassword(
          auth,
          auth.currentUser?.email || "",
          password
        );
      }
      return true;
    } catch (error) {
      console.error("Password reauthentication error:", error);
      return false;
    }
  };

  const handleDeleteAccount = async () => {
    setLoading(true);
    try {
      let isReauthenticated = false;

      if (authProvider === "google") {
        isReauthenticated = await handleGoogleReauthentication();
      } else {
        isReauthenticated = await handlePasswordReauthentication();
      }

      if (!isReauthenticated) {
        throw new Error("Falha na reautenticação");
      }

      if (Capacitor.isNativePlatform()) {
        await FirebaseAuthentication.deleteUser();
      } else {
        await deleteUser(auth.currentUser!);
      }

      toast.success("Conta excluída com sucesso");
      navigate("/");
    } catch (error: any) {
      console.error("Error deleting account:", error);
      let errorMessage = "Erro ao excluir conta. Tente novamente.";

      if (error.code === "auth/wrong-password") {
        errorMessage = "Senha incorreta. Por favor, tente novamente.";
      } else if (error.message.includes("auth/popup-closed-by-user")) {
        errorMessage =
          "Você saiu da tela de login. Por favor, tente novamente.";
      }

      toast.error(errorMessage);
    } finally {
      setLoading(false);
      onClose();
      setShowConfirmation(false);
    }
  };

  return (
    <main className="flex flex-col justify-start items-center gap-8 p-4 min-h-svh pb-20 w-full">
      <motion.div
        className="w-full md:max-w-md"
        initial="hidden"
        animate="visible"
        variants={contentVariants}
      >
        <Button
          className="mb-4 w-full font-medium"
          color="primary"
          variant="flat"
          size="lg"
          startContent={<ArrowLeft size={20} />}
          onPress={handleNavigateBack}
        >
          Voltar para o início
        </Button>

        <div className="w-full bg-default-200 rounded-medium p-2 mb-4">
          <h3 className="text-default-600 font-semibold text-xs">
            Excluir Conta
          </h3>
        </div>

        <Card className="w-full shadow-sm" radius="lg">
          <CardBody className="gap-4 overflow-hidden">
            <motion.div
              className="flex flex-col gap-2 overflow-hidden"
              variants={warningVariants}
            >
              <div className="flex items-center gap-2 text-danger">
                <AlertTriangle size={24} />
                <h2 className="text-xl font-semibold leading-none">
                  Excluir minha conta
                </h2>
              </div>
              <p className="text-sm text-default-600">
                Ao excluir sua conta, todos os seus dados serão permanentemente
                removidos. Esta ação não pode ser desfeita.
              </p>
            </motion.div>

            <AnimatePresence mode="wait">
              {!showConfirmation ? (
                <motion.div
                  key="initial"
                  variants={contentVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <Button
                    color="danger"
                    variant="flat"
                    startContent={<Trash2 size={20} />}
                    className="font-medium w-full"
                    size="lg"
                    radius="md"
                    onPress={() => setShowConfirmation(true)}
                  >
                    Excluir Conta
                  </Button>
                </motion.div>
              ) : (
                <motion.div
                  key="confirmation"
                  variants={contentVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className="flex flex-col gap-4"
                >
                  <div className="flex items-center gap-2 p-3 bg-danger-50 rounded-medium">
                    <AlertCircle className="text-danger" size={20} />
                    <p className="text-sm text-danger-600">
                      Esta ação é irreversível. Todos os seus dados serão
                      excluídos permanentemente.
                    </p>
                  </div>

                  <div className="flex gap-2">
                    <Button color="danger" className="flex-1" onPress={onOpen}>
                      Confirmar Exclusão
                    </Button>
                    <Button
                      color="primary"
                      variant="light"
                      className="flex-1"
                      onPress={() => setShowConfirmation(false)}
                    >
                      Cancelar
                    </Button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </CardBody>
        </Card>
      </motion.div>

      <Modal
        backdrop="blur"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="sm"
        placement="center"
        classNames={{
          backdrop: "bg-black/75",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <span className="text-danger">Confirmação Final</span>
              </ModalHeader>
              <ModalBody>
                <AnimatePresence mode="wait">
                  <motion.div
                    key={authProvider}
                    variants={contentVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    className="flex flex-col gap-3"
                  >
                    {authProvider === "google" ? (
                      <>
                        <p className="text-sm">
                          Para confirmar a exclusão da sua conta, você precisará
                          reautenticar com o Google:
                        </p>
                        <Button
                          color="danger"
                          variant="ghost"
                          size="lg"
                          onPress={handleDeleteAccount}
                          isLoading={loading}
                          className="font-medium"
                          startContent={
                            !loading && (
                              <AnimatePresence mode="wait">
                                <motion.div
                                  key="google-icon"
                                  initial={{ opacity: 0, scale: 0.5 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                  exit={{ opacity: 0, scale: 0.5 }}
                                >
                                  <GoogleIcon width="32px" height="32px" />
                                </motion.div>
                              </AnimatePresence>
                            )
                          }
                        >
                          <AnimatePresence mode="wait">
                            <motion.span
                              key={loading ? "loading" : "static"}
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.2 }}
                            >
                              {loading
                                ? "Excluindo..."
                                : "Confirmar com Google"}
                            </motion.span>
                          </AnimatePresence>
                        </Button>
                      </>
                    ) : (
                      <>
                        <p className="text-sm">
                          Para confirmar a exclusão da sua conta, digite sua
                          senha:
                        </p>
                        <PasswordInput
                          label="Senha"
                          password={password}
                          setPassword={setPassword}
                          variant="bordered"
                        />
                      </>
                    )}
                    <p className="text-xs text-danger">
                      Atenção: Esta ação não pode ser desfeita
                    </p>
                  </motion.div>
                </AnimatePresence>
              </ModalBody>
              <ModalFooter className="p-3">
                <Button variant="light" color="primary" onPress={onClose}>
                  Cancelar
                </Button>
                {authProvider !== "google" && (
                  <Button
                    color="danger"
                    onPress={handleDeleteAccount}
                    isLoading={loading}
                  >
                    Excluir Conta
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </main>
  );
};
