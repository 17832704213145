import React, { useState, useEffect } from "react";
import { Button, Input } from "@nextui-org/react";
import { Drawer } from "vaul";
import {
  doc,
  updateDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { fireStoreDb } from "../../../services/firebase/firebase";
import { toast } from "react-toastify";
import { Check, Copy, Link as LinkIcon, Loader2, X } from "lucide-react";
import debounce from "lodash/debounce";
import { motion, AnimatePresence } from "framer-motion";
import { useCreateZipBio } from "../../../hooks/queries/useCreateZipBio";

interface CustomLinkDrawerProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  initialData: {
    id: string;
    customLink?: string;
  };
  onSuccess: () => void;
  isDismissable?: boolean;
}

export const CustomLinkDrawer: React.FC<CustomLinkDrawerProps> = ({
  isOpen,
  onOpenChange,
  initialData,
  onSuccess,
  isDismissable = true,
}) => {
  const [customLink, setCustomLink] = useState(initialData.customLink || "");
  const [isSaving, setIsSaving] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [initialLink, setInitialLink] = useState(initialData.customLink || "");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  const BASE_URL = "zipsaude.com.br/bio/";
  const fullUrl = `${BASE_URL}${customLink}`;

  const createZipBio = useCreateZipBio();

  // Reset form when drawer opens with new data
  useEffect(() => {
    if (isOpen) {
      setCustomLink(initialData.customLink || "");
      setInitialLink(initialData.customLink || "");
      setIsAvailable(initialData.customLink ? true : null);
      setErrorMessage("");
      setSaveSuccess(false);
    }
  }, [isOpen, initialData]);

  const validateLink = (link: string): { isValid: boolean; error?: string } => {
    const linkPattern = /^[a-zA-Z0-9-]+$/;
    if (!linkPattern.test(link)) {
      return {
        isValid: false,
        error: "O link pode conter apenas letras, números e hífens",
      };
    }

    if (link.length < 3) {
      return {
        isValid: false,
        error: "O link deve ter pelo menos 3 caracteres",
      };
    }

    return { isValid: true };
  };

  const checkLinkAvailability = async (link: string) => {
    if (!link) {
      setIsAvailable(null);
      setErrorMessage("");
      return;
    }

    if (link === initialLink) {
      setIsAvailable(true);
      setErrorMessage("");
      return;
    }

    setIsChecking(true);
    setErrorMessage("");

    try {
      const validation = validateLink(link);
      if (!validation.isValid) {
        setIsAvailable(false);
        setErrorMessage(validation.error || "");
        return;
      }

      const q = query(
        collection(fireStoreDb, "zipBios"),
        where("customLink", "==", link)
      );
      const querySnapshot = await getDocs(q);

      const isLinkAvailable = querySnapshot.empty;
      setIsAvailable(isLinkAvailable);

      if (!isLinkAvailable) {
        setErrorMessage("Este link já está sendo usado");
      }
    } catch (error) {
      console.error("Error checking link availability:", error);
      setIsAvailable(false);
      setErrorMessage("Erro ao verificar disponibilidade do link");
    } finally {
      setIsChecking(false);
    }
  };

  const debouncedCheck = debounce(checkLinkAvailability, 500);

  const handleLinkChange = (value: string) => {
    const formattedLink = value.toLowerCase().trim();
    setCustomLink(formattedLink);

    if (formattedLink === initialLink) {
      setIsAvailable(true);
      setErrorMessage("");
    } else {
      debouncedCheck(formattedLink);
    }
  };

  const handleSave = async () => {
    if (!isAvailable || isChecking || !customLink) return;

    const validation = validateLink(customLink);
    if (!validation.isValid) {
      setErrorMessage(validation.error || "");
      return;
    }

    setIsSaving(true);
    try {
      if (!initialData.customLink) {
        // Creating new ZipBio
        await createZipBio.mutateAsync({
          providerId: initialData.id,
          customLink,
        });
      } else {
        // Just updating existing ZipBio's custom link
        await updateDoc(doc(fireStoreDb, "zipBios", initialData.id), {
          customLink,
          updatedAt: new Date(),
        });
      }

      setSaveSuccess(true);
      toast.success(
        initialData.customLink
          ? "Link personalizado atualizado com sucesso!"
          : "ZipBio criado com sucesso!"
      );

      // Delay closing and reset
      setTimeout(() => {
        onSuccess();
        onOpenChange(false);
        setSaveSuccess(false);
      }, 1500);
    } catch (error) {
      console.error("Error saving custom link:", error);

      // Handle specific error messages from the mutation
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Erro ao salvar link personalizado. Tente novamente.";

      toast.error(errorMessage);
      setSaveSuccess(false);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(fullUrl);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const getInputColor = () => {
    if (!customLink) return "default";
    if (isChecking) return "default";
    if (isAvailable) return "success";
    return "danger";
  };

  const getEndContent = () => {
    if (!customLink) return null;
    if (isChecking) return <Loader2 className="animate-spin" size={18} />;
    if (isAvailable) return <Check size={18} className="text-success" />;
    return <X size={18} className="text-danger" />;
  };

  return (
    <Drawer.Root
      open={isOpen}
      onOpenChange={onOpenChange}
      dismissible={isDismissable}
    >
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 z-10 bg-black/75 backdrop-blur-sm" />
        <Drawer.Content className="bg-white flex flex-col rounded-t-large z-50 mt-24 fixed bottom-0 left-0 right-0 min-h-[50vh]">
          <Drawer.Handle className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-default-300 my-4" />
          <div className="flex flex-col flex-1 p-4 bg-white rounded-t-large">
            <Drawer.Title className="text-center text-xl font-semibold text-default-900 mb-4 px-0.5">
              Link Personalizado
            </Drawer.Title>
            <div className="max-w-md w-full mx-auto flex flex-col flex-1">
              {/* Content Section */}
              <div className="flex-1">
                <div className="flex flex-col gap-6">
                  <Input
                    size="lg"
                    radius="md"
                    variant="flat"
                    label="Seu Link Personalizado"
                    type="text"
                    placeholder="seu-link"
                    value={customLink}
                    onValueChange={handleLinkChange}
                    isDisabled={isSaving}
                    color={getInputColor()}
                    description={
                      errorMessage && (
                        <span className="text-danger text-small">
                          {errorMessage}
                        </span>
                      )
                    }
                    endContent={getEndContent()}
                  />

                  {/* Link Preview */}
                  {customLink && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="flex flex-col gap-2"
                    >
                      <span className="text-default-600 text-small">
                        Preview do Link:
                      </span>
                      <div className="flex items-center gap-2 p-3 bg-default-100 rounded-medium">
                        <LinkIcon size={16} className="text-default-400" />
                        <span className="flex-1 text-default-600 font-medium truncate">
                          {fullUrl}
                        </span>
                        <Button
                          isIconOnly
                          variant="light"
                          size="sm"
                          onPress={handleCopyLink}
                        >
                          <AnimatePresence mode="wait">
                            {showCopied ? (
                              <motion.div
                                key="check"
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0 }}
                              >
                                <Check size={16} className="text-success" />
                              </motion.div>
                            ) : (
                              <motion.div
                                key="copy"
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0 }}
                              >
                                <Copy size={16} />
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </Button>
                      </div>
                    </motion.div>
                  )}
                </div>
              </div>

              {/* Button Section - Now positioned at the bottom */}
              <div className="mt-auto pt-4">
                <motion.div
                  layout
                  animate={{ scale: saveSuccess ? 0.95 : 1 }}
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                >
                  <Button
                    color={saveSuccess ? "success" : "primary"}
                    className="w-full"
                    size="lg"
                    radius="md"
                    onPress={handleSave}
                    isLoading={isSaving}
                    isDisabled={
                      !isAvailable || isChecking || !customLink || saveSuccess
                    }
                  >
                    <AnimatePresence mode="wait">
                      {saveSuccess ? (
                        <motion.div
                          key="success"
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.5 }}
                          className="flex items-center gap-2"
                        >
                          <Check size={20} />
                          <span>Link Salvo!</span>
                        </motion.div>
                      ) : (
                        <motion.div
                          key="save"
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.5 }}
                        >
                          {isSaving ? "Salvando..." : "Salvar Link"}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </Button>
                </motion.div>
              </div>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
