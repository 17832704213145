export const TikTok = ({
  width,
  height,
  className,
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width || "48px"}
      height={height || "54px"}
      viewBox="0 0 32 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M23.7168 13.1003C26.0511 14.7733 28.9123 15.7594 32 15.7594V9.79618C31.4164 9.79618 30.8329 9.7344 30.2592 9.6133V14.3063C27.169 14.3063 24.3103 13.3202 21.976 11.6472V23.8158C21.976 29.9025 17.0563 34.8377 10.9893 34.8377C8.72395 34.8377 6.62115 34.1507 4.87292 32.9743C6.86738 35.0206 9.64977 36.2908 12.7301 36.2908C18.7997 36.2908 23.7193 31.3557 23.7193 25.2689V13.1003H23.7168ZM25.864 7.08518C24.6698 5.77787 23.8867 4.08751 23.7168 2.21922V1.45312H22.0671C22.4832 3.82803 23.8966 5.85695 25.8615 7.08518H25.864ZM8.70918 28.3036C8.04189 27.4263 7.6824 26.3538 7.68486 25.2516C7.68486 22.4664 9.9354 20.2102 12.7104 20.2102C13.2275 20.2102 13.7421 20.2892 14.2346 20.4474V14.3508C13.6584 14.2717 13.0773 14.2371 12.4962 14.2494V18.9943C12.0037 18.8361 11.4891 18.7571 10.9696 18.7571C8.19456 18.7571 5.94402 21.0133 5.94402 23.7985C5.94402 25.7681 7.06929 27.4708 8.70918 28.3036Z"
        fill="#FF004F"
      />
      <path
        d="M21.9759 11.6496C24.3102 13.3227 27.1713 14.3087 30.2591 14.3087V9.61577C28.5355 9.24755 27.0088 8.34306 25.8614 7.08518C23.8965 5.85448 22.4807 3.82556 22.067 1.45312H17.7358V25.2714C17.726 28.0491 15.4804 30.2955 12.7103 30.2955C11.0778 30.2955 9.62751 29.5145 8.70907 28.3061C7.06918 27.4757 5.94392 25.7705 5.94392 23.8034C5.94392 21.0183 8.19445 18.762 10.9695 18.762C11.5013 18.762 12.0135 18.846 12.4961 18.9992V14.2544C6.53487 14.3779 1.74078 19.2637 1.74078 25.2738C1.74078 28.274 2.935 30.9924 4.87282 32.9793C6.62105 34.1556 8.72631 34.8426 10.9892 34.8426C17.0587 34.8426 21.9759 29.9075 21.9759 23.8207V11.6521V11.6496Z"
        fill="black"
      />
      <path
        d="M30.2591 9.61329V8.34305C28.7054 8.34305 27.1788 7.90811 25.8615 7.0827C27.0286 8.36529 28.5675 9.25001 30.2591 9.61329ZM22.0671 1.45064C22.0277 1.22329 21.9981 0.995927 21.976 0.766098V0H15.9975V23.8182C15.9877 26.5935 13.7421 28.8423 10.972 28.8423C10.1594 28.8423 9.39118 28.6496 8.71159 28.3036C9.63003 29.5121 11.0803 30.293 12.7128 30.293C15.4829 30.293 17.7285 28.0441 17.7384 25.2689V1.45064H22.0695H22.0671ZM12.4961 14.2494V12.8976C11.9963 12.8284 11.494 12.7938 10.9892 12.7938C4.91966 12.7938 0 17.729 0 23.8158C0 27.6314 1.9329 30.9948 4.87041 32.9743C2.93259 30.9874 1.73838 28.2665 1.73838 25.2689C1.73838 19.2587 6.53246 14.373 12.4937 14.2494H12.4961Z"
        fill="#00F2EA"
      />
    </svg>
  );
};
