import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  auth,
  fireStoreDb,
  functions,
  googleProvider,
} from "../services/firebase/firebase";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
  User as FirebaseUser,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useRecaptcha } from "./recaptcha.context";
import dayjs from "dayjs";
import { UserInformation } from "../models/UserInformation";
import { DateUtils } from "../utils/dateUtils";
import { httpsCallable } from "firebase/functions";
import { validatePhoneNumber } from "../utils/phoneNumberUtils";
import { notificationService } from "../services/notifications/NotificationService";
import { Capacitor } from "@capacitor/core";
import {
  FirebaseAuthentication,
  User as CapacitorUser,
} from "@capacitor-firebase/authentication";

export interface SignUpCredentials {
  email: string;
  password: string;
  role: string;
}

export interface UserCredentials {
  email: string;
  password: string;
}

export type AuthContextUserProps = {
  uid: string;
  email: string | null;
  displayName?: string | null;
  phoneNumber?: string | null;
  name?: string | null;
  photoURL?: string | null;
  emailVerified: boolean;
  phoneConfirmed?: boolean;
  role: any;
  timezone: string;
  googleAccessToken?: string;
  googleAccessTokenExpiration?: string | null;
  providerId?: string | null;

  location?: UserInformation["location"];
};

interface NormalizedUser {
  uid: string;
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
  emailVerified: boolean;
  phoneNumber: string | null;
}

type PlatformUser = FirebaseUser | CapacitorUser;

export type AuthContextProps = {
  signed: boolean;
  loading: boolean;
  updateUserLoading: boolean;
  currentUser: AuthContextUserProps;
  googleAccessToken: string | null;
  googleAccessTokenExpiration: string | null;

  updateUser: (updatedData: Partial<AuthContextUserProps>) => void;
  handleSignUp: (credentials: SignUpCredentials) => void;
  handleSignIn: (credentials: UserCredentials) => void;
  handleGoogleSignIn: () => Promise<string | undefined>;
  handleResetPassword: (email: string) => void;
  handleSignOut: () => void;
  handleSendPhoneVerificationCode: (phoneNumber: string) => Promise<void>;
  handleVerifyPhoneCode: (
    phoneNumber: string,
    verificationCode: string
  ) => Promise<void>;
  checkAuthStatus: () => boolean;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

export function isKeyOfAuthContextUserProps(
  key: string
): key is keyof AuthContextUserProps {
  // Create a complete AuthContextUserProps object to check against
  const authProps: AuthContextUserProps = {
    uid: "",
    displayName: null,
    email: null,
    phoneNumber: null,
    name: null,
    photoURL: null,
    emailVerified: false,
    phoneConfirmed: false,
    role: null,
    timezone: "",
    googleAccessToken: "",
    googleAccessTokenExpiration: null,
    location: undefined,
  };

  return key in authProps;
}

// Helper function to get ID token regardless of platform
async function getPlatformUserIdToken(
  user: PlatformUser,
  forceRefresh = false
): Promise<string> {
  if (Capacitor.isNativePlatform()) {
    const result = await FirebaseAuthentication.getIdToken({ forceRefresh });
    return result.token;
  } else {
    return (user as FirebaseUser).getIdToken(forceRefresh);
  }
}

// Helper function to normalize user data between Capacitor and Firebase
function normalizeUserData(user: PlatformUser): NormalizedUser {
  const isCapacitorUser = "photoUrl" in user;

  return {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: isCapacitorUser
      ? (user as CapacitorUser).photoUrl
      : (user as FirebaseUser).photoURL,
    emailVerified: user.emailVerified,
    phoneNumber: user.phoneNumber,
  };
}

async function getCurrentPlatformUser(): Promise<PlatformUser | null> {
  if (Capacitor.isNativePlatform()) {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result.user;
  } else {
    return auth.currentUser;
  }
}

const createAuthContextUser = (
  normalizedUser: NormalizedUser,
  userData: any
): AuthContextUserProps => ({
  uid: normalizedUser.uid,
  email: normalizedUser.email,
  phoneNumber: userData?.phoneNumber,
  phoneConfirmed: userData?.phoneConfirmed,
  emailVerified: normalizedUser.emailVerified,
  role: userData?.role,
  name: normalizedUser.displayName,
  photoURL: normalizedUser.photoURL,
  timezone: userData?.timezone || dayjs.tz.guess(),
  location: userData?.location,
  googleAccessToken: userData?.googleAccessToken,
  googleAccessTokenExpiration: userData?.googleAccessTokenExpiration,
  providerId: userData?.providerId,
  displayName: userData?.displayName,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useRecaptcha();
  const [notificationInitialized, setNotificationInitialized] = useState(false);

  const [currentUser, setCurrentUser] = useState<AuthContextUserProps>(
    {} as AuthContextUserProps
  );
  const [loading, setLoading] = useState(true);
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [googleAccessToken, setGoogleAccessToken] = useState<string | null>(
    null
  );
  const [googleAccessTokenExpiration, setGoogleAccessTokenExpiration] =
    useState<string | null>(null);

  const isNativePlatform = () => Capacitor.isNativePlatform();

  const getDataStored = useCallback(() => {
    const storedUser = localStorage.getItem("@ZIP:user");

    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    const initializeNotifications = async () => {
      if (currentUser?.uid && !notificationInitialized) {
        try {
          await notificationService.initialize(navigate);
          setNotificationInitialized(true);
        } catch (error) {
          console.error("Failed to initialize notification service:", error);
        }
      }
    };

    initializeNotifications();
  }, [currentUser?.uid, navigate, notificationInitialized]);

  useEffect(() => {
    if (!auth) return;

    const setupAuthListener = async () => {
      if (isNativePlatform()) {
        // Native auth state listener
        await FirebaseAuthentication.addListener(
          "authStateChange",
          async (change) => {
            if (!change.user) {
              setCurrentUser({} as AuthContextUserProps);
              localStorage.removeItem("@ZIP:user");
              setNotificationInitialized(false);
            } else {
              const userDoc = await getDoc(
                doc(fireStoreDb, "users", change.user.uid)
              );

              if (userDoc.exists()) {
                const userData = userDoc.data();
                const normalizedUser = normalizeUserData(change.user);
                const updatedUser = createAuthContextUser(
                  normalizedUser,
                  userData
                );

                setCurrentUser(updatedUser);
                localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));
              }
            }
            setLoading(false);
          }
        );
      } else {
        // Web auth state listener
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          if (!user) {
            setCurrentUser({} as AuthContextUserProps);
            localStorage.removeItem("@ZIP:user");
            setNotificationInitialized(false);
          } else {
            const userDoc = await getDoc(doc(fireStoreDb, "users", user.uid));

            if (userDoc.exists()) {
              const userData = userDoc.data();
              const normalizedUser = normalizeUserData(user);

              const updatedUser = createAuthContextUser(
                normalizedUser,
                userData
              );

              setCurrentUser(updatedUser);

              localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));
            } else {
              console.error("User document not found");
              setCurrentUser({} as AuthContextUserProps);
              localStorage.removeItem("@ZIP:user");
              setNotificationInitialized(false);
            }
          }
          setLoading(false);
        });
        return () => unsubscribe();
      }
    };

    setupAuthListener();
  }, []);

  async function updateUser(updatedData: Partial<AuthContextUserProps>) {
    try {
      setUpdateUserLoading(true);

      const user = await getCurrentPlatformUser();

      if (!user) {
        throw new Error("No authenticated user found");
      }

      const normalizedUser = normalizeUserData(user);
      const userRef = doc(fireStoreDb, "users", normalizedUser.uid);

      // Filter out undefined values and ensure type safety
      const filteredData = Object.entries(updatedData).reduce(
        (acc, [key, value]) => {
          if (value !== undefined && isKeyOfAuthContextUserProps(key)) {
            acc[key] = value;
          }
          return acc;
        },
        {} as Partial<AuthContextUserProps>
      );

      if (Object.keys(filteredData).length === 0) {
        throw new Error("No valid data to update");
      }

      await updateDoc(userRef, filteredData);

      // Get the latest user data from Firestore
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();

      // Prepare the updated user object
      const updatedUser: AuthContextUserProps = {
        ...currentUser,
        ...filteredData,
        phoneNumber: userData?.phoneNumber || currentUser.phoneNumber,
        phoneConfirmed: userData?.phoneConfirmed || currentUser.phoneConfirmed,
        timezone: userData?.timezone || currentUser.timezone,
        location: userData?.location || currentUser.location,
      };

      setCurrentUser(updatedUser);
      localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));

      // If name is being updated, also update the display name
      if (
        filteredData.name &&
        normalizedUser.displayName !== filteredData.name
      ) {
        if (Capacitor.isNativePlatform()) {
          await FirebaseAuthentication.updateProfile({
            displayName: filteredData.name,
          });
        } else {
          await updateProfile(user as FirebaseUser, {
            displayName: filteredData.name,
          });
        }
      }
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    } finally {
      setUpdateUserLoading(false);
    }
  }

  async function handleSignUp(credentials: SignUpCredentials) {
    try {
      let normalizedUser: NormalizedUser;

      if (isNativePlatform()) {
        // Native signup
        await FirebaseAuthentication.createUserWithEmailAndPassword({
          email: credentials.email,
          password: credentials.password,
        });

        // Get the current user after signup
        const result = await FirebaseAuthentication.getCurrentUser();
        if (!result.user) throw new Error("Failed to get user after signup");

        normalizedUser = normalizeUserData(result.user);
      } else {
        // Web signup
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          credentials.email,
          credentials.password
        );
        normalizedUser = normalizeUserData(userCredential.user);
      }

      await setDoc(doc(fireStoreDb, "users", normalizedUser.uid), {
        email: normalizedUser.email,
        role: credentials.role,
        phoneConfirmed: false,
        timezone: dayjs.tz.guess(),
      });

      const updatedUser = {
        uid: normalizedUser.uid,
        email: normalizedUser.email,
        emailVerified: normalizedUser.emailVerified,
        role: credentials.role,
        phoneConfirmed: false,
        timezone: dayjs.tz.guess(),
      };

      setCurrentUser(updatedUser);
      localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));

      if (credentials.role === "customer") {
        navigate("/");
      } else {
        navigate("/provider");
      }
    } catch (error: any) {
      console.error("Error registering user:", error);
      throw new Error(error);
    }
  }

  async function handleSignIn(credentials: UserCredentials) {
    try {
      if (!isNativePlatform()) {
        await executeRecaptcha();
      }

      let normalizedUser: NormalizedUser;

      if (isNativePlatform()) {
        // Native signin
        await FirebaseAuthentication.signInWithEmailAndPassword({
          email: credentials.email,
          password: credentials.password,
        });

        // Get the current user after signin
        const result = await FirebaseAuthentication.getCurrentUser();
        if (!result.user) throw new Error("Failed to get user after signin");

        normalizedUser = normalizeUserData(result.user);
      } else {
        // Web signin
        const userCredential = await signInWithEmailAndPassword(
          auth,
          credentials.email,
          credentials.password
        );
        normalizedUser = normalizeUserData(userCredential.user);
      }

      const userDoc = await getDoc(
        doc(fireStoreDb, "users", normalizedUser.uid)
      );

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const updatedUser = createAuthContextUser(normalizedUser, userData);

        setCurrentUser(updatedUser);
        localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));

        switch (userData.role) {
          case "admin":
            navigate("/admin/clinics");
            break;
          case "provider":
            navigate("/appointments");
            break;
          default:
            navigate("/");
            break;
        }
      } else {
        console.error("User document not found");
      }
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }

  async function handleGoogleSignIn(): Promise<string | undefined> {
    try {
      let accessToken;
      let normalizedUser: NormalizedUser;

      if (isNativePlatform()) {
        // Native Google signin
        const result = await FirebaseAuthentication.signInWithGoogle({
          scopes: ["https://www.googleapis.com/auth/calendar"],
        });

        accessToken = result.credential?.accessToken;

        const authResult = await FirebaseAuthentication.getCurrentUser();

        if (!authResult.user) {
          throw new Error("Failed to get user after Google signin");
        }

        normalizedUser = normalizeUserData(authResult.user);
      } else {
        // Web Google signin
        googleProvider.setCustomParameters({ prompt: "select_account" });
        googleProvider.addScope("https://www.googleapis.com/auth/calendar");

        const userCredential = await signInWithPopup(auth, googleProvider);
        normalizedUser = normalizeUserData(userCredential.user);

        const credential =
          GoogleAuthProvider.credentialFromResult(userCredential);
        accessToken = credential?.accessToken;
      }

      if (!accessToken) {
        throw new Error("Failed to obtain Google access token");
      }

      const userRef = doc(fireStoreDb, "users", normalizedUser.uid);
      const userDoc = await getDoc(userRef);

      const now = DateUtils.now();
      const tokenExpiration = DateUtils.toDBFormat(now.add(1, "hour"));

      setGoogleAccessToken(accessToken);
      setGoogleAccessTokenExpiration(tokenExpiration);

      let updatedUser: AuthContextUserProps;

      if (!userDoc.exists()) {
        // New user, create a new document
        const userData = {
          email: normalizedUser.email,
          displayName: normalizedUser.displayName,
          phoneNumber: normalizedUser.phoneNumber,
          role: "customer",
          phoneConfirmed: false,
          timezone: dayjs.tz.guess(),
          googleAccessToken: accessToken,
          googleAccessTokenExpiration: tokenExpiration,
          createdAt: new Date(),
        };

        await setDoc(userRef, userData);
        updatedUser = createAuthContextUser(normalizedUser, userData);
      } else {
        // Existing user, update the document
        const userData = userDoc.data();
        const updatedData = {
          ...userData,
          googleAccessToken: accessToken,
          googleAccessTokenExpiration: tokenExpiration,
        };

        // Filter out undefined values and ensure type safety
        const filteredData = Object.entries(updatedData).reduce(
          (acc, [key, value]) => {
            if (value !== undefined && isKeyOfAuthContextUserProps(key)) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Partial<AuthContextUserProps>
        );

        await updateDoc(userRef, filteredData);
        updatedUser = createAuthContextUser(normalizedUser, updatedData);
      }

      setCurrentUser(updatedUser);
      localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));

      return accessToken;
    } catch (error) {
      console.error("Error signing in with Google:", error);
      throw error;
    }
  }

  async function handleResetPassword(email: string) {
    try {
      if (isNativePlatform()) {
        await FirebaseAuthentication.sendPasswordResetEmail({ email });
      } else {
        await sendPasswordResetEmail(auth, email);
      }

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }

  async function handleSignOut() {
    try {
      setCurrentUser({} as AuthContextUserProps);
      localStorage.removeItem("@ZIP:user");
      notificationService.initialized = false;

      if (isNativePlatform()) {
        await FirebaseAuthentication.signOut();
      } else {
        await signOut(auth);
      }

      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  }

  const handleSendPhoneVerificationCode = async (
    phoneNumber: string
  ): Promise<void> => {
    const user = await getCurrentPlatformUser();

    if (!user) {
      throw new Error("Usuário não autenticado");
    }

    try {
      // Get fresh ID token before making the request
      await getPlatformUserIdToken(user, true);

      const sendVerificationCode = httpsCallable<
        { phoneNumber: string },
        { success: boolean }
      >(functions, "sendPhoneVerificationCode", {
        limitedUseAppCheckTokens: true,
      });

      const result = await sendVerificationCode({
        phoneNumber: validatePhoneNumber(phoneNumber),
      });

      // Check the success status explicitly
      if (!result.data.success) {
        throw new Error("Falha ao enviar código de verificação");
      }
    } catch (error: any) {
      console.error("Error sending verification code:", error);

      if (
        error.code === "failed-precondition" &&
        error.message.includes("App Check")
      ) {
        throw new Error("Falha na verificação de segurança. Tente novamente.");
      }
      if (
        error.code === "unauthenticated" ||
        error.code === "permission-denied"
      ) {
        throw new Error("Sessão expirada. Por favor, faça login novamente.");
      }

      // Propagate any specific error message from the server
      throw new Error(
        error.data?.message ||
          error.message ||
          "Erro ao enviar código de verificação"
      );
    }
  };

  const handleVerifyPhoneCode = async (
    phoneNumber: string,
    verificationCode: string
  ) => {
    try {
      const user = isNativePlatform()
        ? (await FirebaseAuthentication.getCurrentUser()).user
        : auth.currentUser;

      if (!user) {
        validatePhoneNumber(phoneNumber);
        throw new Error("No authenticated user found");
      }

      const verifyCode = httpsCallable<
        { phoneNumber: string; code: string },
        { success: boolean; message?: string }
      >(functions, "verifyPhoneCode", { limitedUseAppCheckTokens: true });

      const result = await verifyCode({
        phoneNumber: validatePhoneNumber(phoneNumber),
        code: verificationCode,
      });

      // Explicitly check the success status
      if (!result.data.success) {
        throw new Error(
          result.data.message || "Falha na verificação do código"
        );
      }

      // Update local user state
      setCurrentUser((prevUser) => ({
        ...prevUser,
        phoneNumber: `+55${phoneNumber}`,
        phoneConfirmed: true,
      }));

      // Update local storage
      const updatedUser = {
        ...currentUser,
        phoneNumber: `+55${phoneNumber}`,
        phoneConfirmed: true,
      };
      localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));
    } catch (error: any) {
      console.error("Error verifying phone code:", error);

      // Propagate specific error messages
      if (
        error.code === "failed-precondition" &&
        error.message.includes("App Check")
      ) {
        throw new Error("Falha na verificação de segurança. Tente novamente.");
      }
      if (
        error.code === "unauthenticated" ||
        error.code === "permission-denied"
      ) {
        throw new Error("Sessão expirada. Por favor, faça login novamente.");
      }

      // Throw the most specific error message available
      throw new Error(
        error.data?.message || error.message || "Erro na verificação do código"
      );
    }
  };

  useEffect(() => {
    getDataStored();
  }, [getDataStored]);

  const checkAuthStatus = () => {
    return !loading && Boolean(currentUser.email);
  };

  return (
    <AuthContext.Provider
      value={{
        signed: checkAuthStatus(),
        loading,
        currentUser,
        googleAccessToken,
        googleAccessTokenExpiration,
        updateUser,
        updateUserLoading,
        handleSignUp,
        handleSignIn,
        handleGoogleSignIn,
        handleResetPassword,
        handleSignOut,
        checkAuthStatus,
        handleSendPhoneVerificationCode,
        handleVerifyPhoneCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
