import { Input, InputProps } from "@nextui-org/react";
import React, { useCallback, useMemo, useState } from "react";
import { PasswordToggle } from "../../Atoms/PasswordToggle";
import { motion, AnimatePresence } from "framer-motion";
import { Check } from "lucide-react";
import { OTPInput as InputOTP, SlotProps } from "input-otp";
import { validateEmail } from "../../../utils/validateEmail";
import {
  cleanPhoneNumber,
  normalizePhoneNumber,
} from "../../../utils/phoneNumberUtils";

interface EmailInputProps {
  email: string;
  setEmail: (value: string) => void;
}

interface PasswordInputProps {
  password: string;
  setPassword: (value: string) => void;
  isInvalid?: boolean;
  errorMessage?: string;
  label?: string;
  variant?: "faded" | "flat" | "bordered" | "underlined" | undefined;
}

interface PhoneNumberInputProps extends Omit<InputProps, "value" | "onChange"> {
  value: string;
  onChange: (value: string) => void;
}
interface OTPInputProps {
  verificationCode: string;
  setVerificationCode: React.Dispatch<React.SetStateAction<string>>;
}

const counterVariants = {
  initial: { scale: 0.8, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0.8, opacity: 0 },
};

const inputContainerVariants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.15,
    },
  },
};

const inputItemVariants = {
  initial: { scale: 0.8, opacity: 0 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20,
    },
  },
};

export const EmailInput: React.FC<EmailInputProps> = ({ email, setEmail }) => {
  const isInvalid = useMemo(() => {
    return email !== "" && !validateEmail(email);
  }, [email]);

  return (
    <Input
      size="lg"
      type="email"
      label="E-mail"
      variant="faded"
      isInvalid={isInvalid}
      color={isInvalid ? "danger" : "default"}
      errorMessage="Digite um e-mail válido"
      value={email}
      onValueChange={setEmail}
      isRequired
    />
  );
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  setPassword,
  isInvalid,
  errorMessage,
  variant,
  label = "Senha",
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Input
      size="lg"
      type={isVisible ? "text" : "password"}
      endContent={
        <PasswordToggle
          isVisible={isVisible}
          onClick={() => setIsVisible(!isVisible)}
        />
      }
      label={label}
      variant={variant || "faded"}
      value={password}
      onValueChange={setPassword}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      isRequired
    />
  );
};

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [maskedValue, setMaskedValue] = useState(() =>
    normalizePhoneNumber(cleanPhoneNumber(value))
  );

  const handleChange = useCallback(
    (inputValue: string) => {
      const cleanedValue = cleanPhoneNumber(inputValue);
      const formatted = normalizePhoneNumber(cleanedValue);
      setMaskedValue(formatted);
      onChange(cleanedValue);
    },
    [onChange]
  );

  return (
    <Input
      {...props}
      value={maskedValue}
      onValueChange={handleChange}
      placeholder="(XX) XXXXX-XXXX"
      type="tel"
      maxLength={15}
      autoComplete="tel-national"
      startContent={<span className="text-default-400 text-small">+55</span>}
    />
  );
};

// Separate Slot component for better organization
const Slot = ({
  char,
  isActive,
  isComplete,
  ...props
}: SlotProps & { isComplete?: boolean }) => {
  return (
    <motion.div
      {...props}
      className={` relative w-11 h-12 flex items-center justify-center rounded-large transition-[box-shadow,colors,border-color] text-lg font-medium bg-transparent group  border-2 ${
        isActive ? "ring-2 ring-primary/80 ring-offset-2" : "ring-0"
      } ${char ? "border-primary" : "border-default-200"} ${
        isComplete
          ? "border-success data-[has-value=true]:border-success ring-success/80 "
          : ""
      } shadow-sm hover:shadow-md cursor-text data-[has-value=true]:border-primary data-[focus=true]:border-primary data-[invalid=true]:border-danger data-[invalid=true]:group-data-[focus=true]:border-danger focus:border-primary
      `}
      variants={inputItemVariants}
      data-has-value={!!char}
      data-focus={isActive}
    >
      {char || <div className="w-2 h-2 rounded-full bg-default-300 absolute" />}
    </motion.div>
  );
};

export const OTPInput: React.FC<OTPInputProps> = ({
  verificationCode,
  setVerificationCode,
}) => {
  const isComplete = verificationCode.length === 6;

  const handleOTPChange = (value: string) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    setVerificationCode(numericValue);
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-default-700">
            Código de Verificação
          </p>
          <p className="text-xs text-default-500">
            Digite o código de 6 dígitos enviado por SMS
          </p>
        </div>
        <AnimatePresence mode="wait">
          {isComplete ? (
            <motion.div
              key="complete"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={counterVariants}
              className="bg-success/20 text-success px-2 py-1 rounded-full flex items-center gap-1"
            >
              <Check size={12} />
              <span className="text-xs font-medium">6/6</span>
            </motion.div>
          ) : (
            <motion.div
              key="counting"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={counterVariants}
              className="flex items-center gap-1"
            >
              <div className="w-4 h-4 rounded-full bg-primary/20 flex items-center justify-center">
                <span className="text-[10px] text-primary font-medium">
                  {verificationCode.length}
                </span>
              </div>
              <span className="text-xs text-default-500">/</span>
              <span className="text-xs text-default-500">6</span>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <motion.div
        variants={inputContainerVariants}
        initial="initial"
        animate="animate"
        className="flex justify-center"
      >
        <InputOTP
          value={verificationCode}
          onChange={handleOTPChange}
          maxLength={6}
          pattern="\d*"
          inputMode="tel"
          containerClassName="gap-1 justify-center py-1"
          render={({ slots }) => (
            <div className="flex gap-1.5">
              {slots.map((slot, idx) => (
                <Slot key={idx} {...slot} isComplete={isComplete} />
              ))}
            </div>
          )}
        />
      </motion.div>
    </div>
  );
};
