import React from "react";
import { LocationPreference } from "../LocationPreference";
import { NotificationPreference } from "../NotificationPreference";
import { AuthContextUserProps } from "../../../contexts/auth.context";

interface PreferencesSectionProps {
  isLoadingStates: boolean;
  isLoadingCities: boolean;
  handleStateChange: (stateId: string) => void;
  handleCityChange: (cityId: string) => void;
  selectedState?: { id: string };
  selectedCity?: { id: string };
  stateItems: { key: string; label: string }[];
  cityItems: { key: string; label: string }[];
  isManualLocationMutationLoading: boolean;
  handleLocationUpdate: () => void;
  user: AuthContextUserProps;
  timezone: string;
  handleTimezoneChange: (value: string) => void;
  updateUser: (data: any) => void;
  isTimezoneDisabled: boolean;
  isNotificationEnabled: boolean;
  handleNotificationToggle: () => void;
}

export const PreferencesSection: React.FC<PreferencesSectionProps> = (
  props
) => (
  <div className="flex flex-col gap-2">
    <div className="w-full bg-default-200 rounded-medium p-2">
      <h3 className="text-default-600 font-semibold text-xs">Preferências</h3>
    </div>
    <div className="flex flex-col gap-6">
      <LocationPreference
        isLoadingStates={props.isLoadingStates}
        isLoadingCities={props.isLoadingCities}
        handleStateChange={props.handleStateChange}
        handleCityChange={props.handleCityChange}
        selectedState={props.selectedState}
        selectedCity={props.selectedCity}
        stateItems={props.stateItems}
        cityItems={props.cityItems}
        isManualLocationMutationLoading={props.isManualLocationMutationLoading}
        handleLocationUpdate={props.handleLocationUpdate}
        user={props.user}
      />

      <NotificationPreference
        isNotificationEnabled={props.isNotificationEnabled}
        handleNotificationToggle={props.handleNotificationToggle}
      />
    </div>
  </div>
);
