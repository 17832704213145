import { useQuery } from "@tanstack/react-query";
import { getDoc, doc } from "firebase/firestore";
import { fireStoreDb, auth } from "../../services/firebase/firebase";
import { UserInformation } from "../../models/UserInformation";
import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

export const useUserInformationQuery = () => {
  const isNativePlatform = Capacitor.isNativePlatform();

  return useQuery<UserInformation, Error>({
    queryKey: ["users", "current"],
    queryFn: async () => {
      let userId: string | undefined;

      if (isNativePlatform) {
        const result = await FirebaseAuthentication.getCurrentUser();
        userId = result.user?.uid;
      } else {
        userId = auth.currentUser?.uid;
      }

      if (!userId) throw new Error("No user logged in");

      const docRef = doc(fireStoreDb, "users", userId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) throw new Error("User not found");
      return docSnap.data() as UserInformation;
    },
    enabled: true, // The function will handle the null case internally
  });
};
